import React, { useContext, useEffect, useState } from "react";
import SelectedMenuPage from "./components/SelectedMenuPage";
import axiosService from "../../api/xmmAxios";
import { writeLogEntry } from "../../services/log.service";
import { useNewQuoteContext, Actions } from "../../state/NewQuoteContext";
import EditServiceModule from "../../EditServiceModule";
import { AppContext } from "../../state/app-context";
import isEmpty from "lodash/isEmpty";
import menuServiceUtil from "../../ServiceSearchModule/utils/menu-service-calculation.util";

const MenuPackageWrapper = () => {
  const appConfig = useContext(AppContext);
  const { dispatch, state } = useNewQuoteContext();
  const {
    user: { userName },
    dealer: { dealerCode }
  } = appConfig;
  const {
    debugMode,
    menuPackage,
    payTypes,
    payTypeSubTypes,
    costAllocationTypes,
    vehicle,
    userPermissions,
    calculatedMenuPackage,
    dealerProperties
  } = state;
  let service = menuPackage ? menuPackage.rawService : null;
  const [defaultPayTypeOption, setDefaultPayTypeOption] = useState("-1");

  useEffect(() => {
    axiosService.setupAxios(appConfig);
    dispatch({
      type: Actions.SET_MENU_PACKAGE
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(service)) {
      if (!isEmpty(payTypes)) {
        const selectedDefault = menuServiceUtil.getDefaultPayTypeForMenu(
          service,
          payTypes,
          vehicle.make
        );

        if (!isEmpty(selectedDefault)) {
          const {
            payCode: selectedPayCode,
            description: selectedDescription,
            dealerPayTypeId
          } = selectedDefault;
          const defaultPayTypeOption = !dealerPayTypeId
            ? "-1"
            : String(dealerPayTypeId);

          setDefaultPayTypeOption(defaultPayTypeOption);
          service = menuServiceUtil.updateMenuWithPayTypeChanges(
            selectedPayCode,
            selectedDescription,
            service
          );
        }
      }

      dispatch({
        type: Actions.SET_CALCULATED_MENU_PACKAGE,
        payload: menuServiceUtil.formatMenuPackage(service)
      });
    }
  }, [service]);

  return !isEmpty(calculatedMenuPackage) ? (
    <SelectedMenuPage
      EditServiceModule={
        <EditServiceModule
          commonConsumerId={
            state?.quoteSummary?.customer?.commonConsumerId ?? ""
          }
          debugMode={debugMode}
          axiosInstance={axiosService}
          writeLogEntry={writeLogEntry}
          vehicle={vehicle}
          payTypes={payTypes}
          payTypeSubTypes={payTypeSubTypes}
          costAllocationTypes={costAllocationTypes}
          username={userName}
          dealerCode={dealerCode}
          userPermissions={userPermissions}
          quoteSummary={state?.quoteSummary}
          dealerProperties={dealerProperties}
        />
      }
      defaultPayTypeOption={defaultPayTypeOption}
    />
  ) : null;
};

export default MenuPackageWrapper;
